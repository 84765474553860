import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import './style.css';
import App from './App.vue';
import { plugin, defaultConfig } from '@formkit/vue';
import { generateClasses } from '@formkit/themes';
import theme from './formkit-theme.ts';
import { router } from './helpers';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import initSentry from './Sentry.ts';
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleArrowDown,
  faSquareCaretDown,
  faSquareCaretUp,
  faCircleXmark,
  faCircleCheck,
  faTriangleExclamation,
  faPaperPlane,
  faEnvelope,
  faClipboard,
  faCheck,
  faQrcode,
  faCirclePlus,
  faCircleMinus,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPlus,
  faMinus,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faMessage,
  faFolderOpen,
  faPen,
  faGear,
  faList,
  faBarsStaggered,
  faEye,
  faEyeSlash,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleArrowUp,
  faCircleArrowDown,
  faSquareCaretDown,
  faSquareCaretUp,
  faCircleXmark,
  faCircleCheck,
  faTriangleExclamation,
  faPaperPlane,
  faEnvelope,
  faClipboard,
  faCheck,
  faQrcode,
  faCirclePlus,
  faCircleMinus,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faPlus,
  faMinus,
  faArrowRight,
  faArrowLeft,
  faArrowUp,
  faArrowDown,
  faMessage,
  faFolderOpen,
  faPen,
  faGear,
  faList,
  faBarsStaggered,
  faEye,
  faEyeSlash,
  faPlay
);
const app = createApp(App);
if (import.meta.env.NODE_ENV === 'production') {
  initSentry(app, router);
}
app.use(createPinia());
app.component('font-awesome-icon', FontAwesomeIcon);

app.use(
  plugin,
  defaultConfig({
    config: {
      classes: generateClasses(theme),
    },
  })
);
app.use(router);
app.use(Toast, {
  position: POSITION.TOP_RIGHT,
});
app.mount('#app');
export { router };
