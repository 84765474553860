import { defineStore } from 'pinia';

import { axiosWrapper, router } from '../helpers';
import { computed, ref } from 'vue';
import jwtDecode from 'jwt-decode';
function getLocalStorageKey() {
  const host = window.location.host;
  return host;
}

export const useAuthStore = defineStore('auth', () => {
  const storedToken = ref(localStorage.getItem(`token-${getLocalStorageKey}`));
  const storedUser = ref(localStorage.getItem(`user-${getLocalStorageKey}`));

  const token = computed(() => {
    if (storedToken.value && storedToken.value !== 'undefined') {
      return storedToken.value;
    }
    return null;
  });
  const user = computed(() => {
    if (storedUser.value && storedUser.value !== 'undefined') {
      return JSON.parse(storedUser.value);
    }
    return null;
  });
  function isLoggedIn(): boolean {
    const payload = getTokenPayload();
    if (payload.exp < Date.now() && payload.exp !== 0) {
      return true;
    }
    return false;
  }
  interface Payload {
    role: string;
    user_owner_id: string;
    organization_id: string;
    organization_name: string;
    sub: string;
    subscriptionActive: boolean;
    exp: number;
    iat: number;
    organization_owner_id: string;
  }
  function getTokenPayload(): Payload {
    const tokenValue = token.value;
    if (!tokenValue) {
      return {
        role: '',
        sub: '',
        user_owner_id: '',
        subscriptionActive: false,
        exp: 0,
        iat: 0,
        organization_id: '',
        organization_name: '',
        organization_owner_id: '',
      };
    }
    const decoded = jwtDecode<Payload>(tokenValue);

    return decoded;
  }

  async function register(
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) {
    const res = await axiosWrapper.post('/auth/register', {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
    });
    return res;
  }

  async function login(email: string, password: string) {
    const res = await axiosWrapper.post('/auth/login', {
      email: email,
      password: password,
    });
    if (res.status !== 200) return;
    // update pinia state
    // store user details and jwt in local storage to keep user logged in between page refreshes

    updateUser(JSON.stringify(res.data));
    if (getTokenPayload().subscriptionActive) {
      await router.push('/plans');
    } else if (!getTokenPayload().subscriptionActive) {
      await router.push('/subscribe');
    }
    return;
    // redirect to previous url or default to home page
  }
  function updateUser(user: string) {
    localStorage.setItem(`user-${getLocalStorageKey}`, user);
    storedUser.value = localStorage.getItem(`user-${getLocalStorageKey}`);
  }
  function updateToken(token: string) {
    localStorage.setItem(`token-${getLocalStorageKey}`, token);
    storedToken.value = localStorage.getItem(`token-${getLocalStorageKey}`);
  }
  async function logout() {
    localStorage.removeItem(`user-${getLocalStorageKey}`);
    localStorage.removeItem(`token-${getLocalStorageKey}`);
    storedToken.value = null;
    storedUser.value = null;
  }
  return {
    token,
    login,
    logout,
    register,
    payload: getTokenPayload,
    updateToken,
    user,
    isLoggedIn,
  };
});
