import { useAuthStore, useLoadingStore } from '../stores';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useToast } from 'vue-toastification';
import { router } from '.';
const toast = useToast();

export const axiosWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
  uploadExercise: uploadExerciseRequest(),
};

function request(method: string) {
  return (url: string, body?: Record<string, unknown>) => {
    const loading = useLoadingStore();
    loading.startLoad();
    url = `${import.meta.env.VITE_APP_API_URL}${url}`;
    const axiosConfig: AxiosRequestConfig = {
      method: method,
      url: url,
      headers: authHeader(url),
    };
    if (body && axiosConfig.headers !== undefined) {
      axiosConfig.headers['Content-Type'] = 'application/json';
      axiosConfig.data = JSON.stringify(body);
    }
    return axios(axiosConfig).then(handleResponse).catch(handleError);
  };
}

// helper functions

function authHeader(url: string): { [key: string]: string } {
  const { token } = useAuthStore();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(import.meta.env.VITE_APP_API_URL as string);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function handleResponse(response: AxiosResponse) {
  const loading = useLoadingStore();
  loading.stopLoad();
  if (response.headers['authorization']) {
    const token = response.headers['authorization'].split(' ')[1];
    const { updateToken } = useAuthStore();
    updateToken(token);
  }
  return response;
}

function handleError(error: any) {
  const loading = useLoadingStore();
  loading.stopLoad();
  // Extract error response
  const { response } = error;
  if (!response) {
    // If no response, it could be a network error or something else
    toast.error('Network error');
    return 'Network error';
  }

  const { token, logout } = useAuthStore();
  if ([401, 403].includes(response.status) && token) {
    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
    logout();
    router.push('/login');
  }

  let errMsg = (response.data && response.data.message) || response.statusText;
  if (response.data.message) {
    toast.error(errMsg, {
      timeout: 2000,
    });
  }

  return errMsg;
}
function uploadExerciseRequest() {
  return (
    url: string,
    file: any,
    title: string,
    instructions: string[],
    tags: {}[],
    file_type: string
  ) => {
    const loading = useLoadingStore();
    loading.startLoad();
    url = `${import.meta.env.VITE_APP_API_URL}${url}`;
    // Create FormData object and append the file
    const formData = new FormData();
    formData.append('video', file);
    formData.append('title', title);
    formData.append('instructions', instructions.join('|'));
    formData.append('tags', JSON.stringify(tags));
    formData.append('file_type', file_type);

    const axiosConfig: AxiosRequestConfig = {
      method: 'POST',
      url: url,
      headers: {
        ...authHeader(url),
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };

    return axios(axiosConfig).then(handleResponse).catch(handleError);
  };
}
