// const textClassification = {
//   wrapper: 'w-full',
//   label: 'mb-1 text-md formkit-invalid:text-red-500',
//   inner: `
//       border
//       border-gray-200
//       formkit-invalid:border-red-500
//       rounded-lg mb-1
//       focus-within:border-primary
//     `,
//   input: 'w-full h-10 px-3 border-none text-base placeholder-gray-400 rounded',
//   suffixIcon: 'h-10 flex self-stretch grow-0 shrink-0',
// };

// const buttonClassification = {
//   wrapper: 'mb-1 p-4',
//   input:
//     'enabled:md:hover:shadow-xl text-md font-normal py-2 px-4 duration-500 bg-white rounded-md',
// };

const config = {
  //   global: {
  //     wrapper: 'w-full',
  //     outer: 'mb-5 formkit-disabled:opacity-50',
  //     help: 'text-xs text-gray-500',
  //     messages: 'list-none p-0 mt-1 mb-0',
  //     message: 'text-red-500 mb-1 text-xs',
  //   },
  //   button: buttonClassification,
  //   email: textClassification,
  //   password: textClassification,
  //   submit: buttonClassification,
  //   text: textClassification,
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    fieldset: 'px-2 pb-1',
    help: 'text-xs text-gray-500 text-center',
    inner:
      'border formkit-invalid:border-red-500 focus-within:border-primary bg-white rounded-lg',
    input:
      'appearance-none focus:outline-none focus:ring-0 focus:shadow-none rounded-lg',
    label: 'block mb-1 font-bold text-sm',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-red-500 mb-1 text-xs text-center',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'formkit-disabled:opacity-50',
    prefixIcon:
      'w-10 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      'md:flex self-stretch grow-0 shrink-0 w-full max-w-[3em] px-2 rounded-lg md:pt-0 pt-1',
  },

  // Family styles apply to all inputs that share a common family

  'family:button': {
    input:
      '$reset inline-flex items-center enabled:md:hover:shadow-xl py-2 px-4 rounded duration-500 w-full bg-primary text-white enabled:md:hover:bg-primaryDark justify-center formkit-disabled:opacity-50 mt-2',
    wrapper: 'my-2 text-center',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },
  'family:text': {
    inner: 'flex items-center rounded w-full',
    input:
      'w-full px-3 py-2 border-none text-base text-gray-700 placeholder-gray-400 rounded-lg',
  },

  select: {
    inner:
      'flex relative max-w-md items-center rounded mb-1 ring-1 ring-gray-400 focus-within:ring-primary focus-within:ring-1 [&>span:first-child]:focus-within:text-primary',
    input:
      'w-full pl-3 pr-8 py-2 border-none text-base text-gray-700 placeholder-gray-400 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon:
      'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700',
  },
  textarea: {
    inner:
      'flex max-w-md rounded mb-1 ring-1 ring-gray-400 focus-within:ring-primary [&>label:first-child]:focus-within:text-primary',
    input:
      'block w-full h-32 px-3 py-3 border-none text-base text-gray-700 placeholder-gray-400 focus:shadow-outline',
  },
  file: {
    fileName: 'text-ellipsis text-gray-800',
    fileRemove: 'text-sm text-gray-700',
    noFiles: 'text-gray-400',
  },
};
export default config;
