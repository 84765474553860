import { createRouter, createWebHistory } from 'vue-router';

import { useAuthStore } from '../stores';
const routes = [
  {
    path: '/',
    component: () => import('../components/Patient/Landing.vue'),
  },
  { path: '/login', component: () => import('../components/Login.vue') },
  {
    path: '/plan/:short_code',
    component: () => import('../components/Patient/PlanWrapper.vue'),
    props: true,
  },
  { path: '/home', component: () => import('../components/Home.vue') },
  {
    path: '/forgot-password',
    component: () => import('../components/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    component: () => import('../components/ResetPassword.vue'),
  },
  {
    path: '/exercises',
    component: () => import('../components/Exercise/Index.vue'),
  },
  {
    path: '/plans',
    component: () => import('../components/ExercisePlan/Index.vue'),
  },
  {
    path: '/subscribe',
    component: () => import('../components/Subscription/Index.vue'),
  },
  {
    path: '/exercise/:shortcode',
    name: 'DisplayExercise',
    component: () => import('../components/Exercise/DisplayExercise.vue'),
    props: true,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../components/Settings/Index.vue'),
  },
  {
    path: '/confirm/:confirmCode',
    component: () => import('../components/ConfirmEmail.vue'),
    props: true,
  },
  {
    path: '/success/:session_id',
    component: () => import('../components/Success.vue'),
    props: true,
  },
];
export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
});

router.beforeEach(async (to) => {
  const auth = useAuthStore();

  const tokenValid = auth.payload().exp > Date.now() / 1000;
  const publicPagePatterns = [
    /^\/$/,
    /^\/login.*$/,
    /^\/forgot-password.*$/,
    /^\/reset-password.*$/,
    /^\/plan\/.*$/,
    /^\/confirm\/.*$/,
    /^\/success\/.*$/,
  ];
  const authRequired = !publicPagePatterns.some((pattern) =>
    pattern.test(to.path)
  );
  // const adminPages = [/^\/exercises(\/.*)?$/];
  // const adminRequired = adminPages.some((pattern) => pattern.test(to.path));
  if (!tokenValid && authRequired && to.path !== '/login') {
    await auth.logout();
    return '/login';
  }

  if (tokenValid && to.path === '/login') {
    return '/plans';
  }

  if (authRequired && !auth.token && !auth.user) {
    return '/login';
  }

  // const role = auth.payload().role;
  // const adminRequired = false;

  // if (adminRequired && role !== 'su' && role !== 'admin') {
  //   return '/plans';
  // }
});
