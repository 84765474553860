// stores/theme.ts

import { defineStore } from 'pinia';

// Create a function to get a unique localStorage key based on the current URL
function getLocalStorageKey() {
  const host = window.location.host;
  return `disclosure-${host}`;
}
function getCurrentTimestamp() {
  return new Date().getTime();
}
function isExpired(timestamp: number) {
  const oneYearInMillis = 365 * 24 * 60 * 60 * 1000; // 1 year in milliseconds
  const currentTimestamp = getCurrentTimestamp();
  return currentTimestamp - timestamp > oneYearInMillis;
}
export const useDisclosureStore = defineStore({
  id: 'disclosure',
  state: () => ({
    accepted: JSON.parse(localStorage.getItem(getLocalStorageKey()) || 'null'),
  }),
  actions: {
    acceptDisclosure() {
      const accepted = {
        timestamp: getCurrentTimestamp(),
      };
      localStorage.setItem(getLocalStorageKey(), JSON.stringify(accepted));
    },
    checkDisclosure(): boolean {
      if (this.accepted !== null) {
        if (isExpired(this.accepted.timestamp)) {
          this.accepted = null;
          localStorage.removeItem(getLocalStorageKey());
          return true;
        }
        return false;
      }
      return true;
    },
  },
});
