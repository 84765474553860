// stores/theme.ts

import { defineStore } from 'pinia';
import { axiosWrapper } from '../helpers';

type ThemeType = {
  primary_color: string;
  primary_light_color: string;
  primary_dark_color: string;
  icon?: string;
} | null;

interface ThemeState {
  theme: ThemeType;
  initialized: boolean;
}
// Create a function to get a unique localStorage key based on the current URL
function getLocalStorageKey() {
  const host = window.location.host;
  return `theme-${host}`;
}

export const useThemeStore = defineStore({
  id: 'theme',
  state: (): ThemeState => ({
    theme: JSON.parse(localStorage.getItem(getLocalStorageKey()) || 'null'),
    initialized: false,
  }),
  actions: {
    async getTheme() {
      const res = await axiosWrapper.get('/themes');
      if (res.status !== 200) return;
      if (res.data.theme) {
        const newTheme = res.data.theme;
        localStorage.setItem(getLocalStorageKey(), JSON.stringify(newTheme));
        this.theme = JSON.parse(
          localStorage.getItem(getLocalStorageKey()) || 'null'
        );
        this.setStoredTheme();

        return true;
      }
      return false;
    },
    async setTheme() {
      if (this.initialized) {
        return;
      }
      if (!this.theme) {
        await this.getTheme();
        return;
      } else {
        this.setStoredTheme();
        // await this.getTheme();
      }
    },
    setStoredTheme() {
      this.applyTheme(this.theme);
    },
    applyTheme(theme: ThemeType) {
      if (!theme) return;
      this.initialized = true;
      const rootStyle = document.documentElement.style;
      rootStyle.setProperty('--color-primary', theme.primary_color);
      rootStyle.setProperty('--color-primaryLight', theme.primary_light_color);
      rootStyle.setProperty('--color-primaryDark', theme.primary_dark_color);
    },
    getCurrentTheme() {
      const rootStyle = document.documentElement.style;
      const primary = rootStyle.getPropertyValue('--color-primary');
      const primaryLight = rootStyle.getPropertyValue('--color-primaryLight');
      const primaryDark = rootStyle.getPropertyValue('--color-primaryDark');
      return {
        primary_color: primary,
        primary_light_color: primaryLight,
        primary_dark_color: primaryDark,
        icon: this.theme?.icon,
      };
    },
    resetTheme() {
      this.theme = null;
      localStorage.removeItem(getLocalStorageKey());
    },
  },
});
