// src/store.js
import { defineStore } from 'pinia';

export const useLoadingStore = defineStore({
  id: 'loading',
  state: () => ({
    loading: false,
    overriden: false,
  }),
  actions: {
    override() {
      this.overriden = true;
    },
    startLoad() {
      if (this.overriden) return;
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
      this.overriden = false;
    },
  },
});
